/**
 * Loader component to display Lottie animation
 * for a specific amount of time
 */
import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../images/loader.json';

import PropTypes from 'prop-types';

type LoaderProp = {
  finishedLoading: () => void;
};

const Loader = ({ finishedLoading }: LoaderProp) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      finishedLoading();
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="loader-page">
      <Lottie options={defaultOptions} height={265} width={170} />
      <p>...LOADING</p>
    </div>
  );
};

export default Loader;
