import React from 'react'

const LinkedinIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg"
        role="img"
        viewBox="0 0 46 46"
        fill="none"
        className="linkedIn_icon">
        <path d="M35.968 0H9.465A9.466 9.466 0 000 9.465v26.5a9.466 9.466 0 009.465 9.465h26.5a9.465 9.465 0 009.465-9.465v-26.5A9.465 9.465 0 0035.968 0zM15.145 35.968h-5.68V15.144h5.679zm-2.84-23.224A3.339 3.339 0 1115.618 9.4a3.327 3.327 0 01-3.313 3.344zm25.557 23.224h-5.68V25.359c0-6.376-7.572-5.893-7.572 0v10.609h-5.679V15.144h5.679v3.341c2.643-4.9 13.252-5.257 13.252 4.687z"/>
    </svg>
)

export default LinkedinIcon
