import React from 'react'

const NodejsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77.76 47.624"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="nodejs_icon"
  >
    <defs>
      <clipPath id="a">
        <path
          d="M123.723,62.291l-7.386,4.264a.892.892,0,0,0-.446.772V75.86a.893.893,0,0,0,.446.773l7.386,4.267a.892.892,0,0,0,.893,0L132,76.633a.893.893,0,0,0,.446-.773V67.327a.892.892,0,0,0-.446-.773l-7.388-4.264a.892.892,0,0,0-.892,0"
          transform="translate(-115.89 -62.171)"
        />
      </clipPath>
      <clipPath id="b">
        <path
          d="M123.724,62.291l-7.388,4.264a.892.892,0,0,0-.446.773V75.86a.893.893,0,0,0,.446.773l7.388,4.267a.892.892,0,0,0,.893,0L132,76.633a.893.893,0,0,0,.446-.773V67.327a.892.892,0,0,0-.446-.773l-7.386-4.264a.892.892,0,0,0-.892,0"
          transform="translate(-115.89 -62.171)"
          fill="#64c0e8"
        />
      </clipPath>
      <clipPath id="c">
        <rect width="16.128" height="18.821" fill="#64c0e8" />
      </clipPath>
      <clipPath id="d">
        <path
          d="M126.539,62.175a.893.893,0,0,0-.389.118l-7.317,4.223L126.781,81a.885.885,0,0,0,.241-.095l7.37-4.267a.861.861,0,0,0,.411-.773v-.034L126.83,62.208a.909.909,0,0,0-.245-.034h-.047"
          transform="translate(-118.832 -62.174)"
          fill="#64c0e8"
        />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <path
        d="M178.813,181.074a1.472,1.472,0,0,1-.737-.2l-2.345-1.387c-.35-.2-.179-.265-.064-.306a4.68,4.68,0,0,0,1.06-.482.18.18,0,0,1,.174.013l1.8,1.069a.231.231,0,0,0,.217,0l7.022-4.053a.221.221,0,0,0,.107-.19v-8.1a.226.226,0,0,0-.109-.194l-7.019-4.05a.22.22,0,0,0-.216,0l-7.018,4.051a.226.226,0,0,0-.111.192v8.1a.216.216,0,0,0,.111.188l1.924,1.111c1.044.522,1.683-.093,1.683-.71v-8a.2.2,0,0,1,.2-.2h.89a.2.2,0,0,1,.2.2v8a1.946,1.946,0,0,1-2.079,2.192,3,3,0,0,1-1.618-.44l-1.842-1.06a1.483,1.483,0,0,1-.736-1.281v-8.1a1.48,1.48,0,0,1,.736-1.28l7.022-4.058a1.539,1.539,0,0,1,1.476,0l7.022,4.058a1.482,1.482,0,0,1,.738,1.28v8.1a1.487,1.487,0,0,1-.738,1.281l-7.022,4.054a1.472,1.472,0,0,1-.739.2"
        transform="translate(-140.38 -133.45)"
        fill="#64c0e8"
      />
      <path
        d="M213.561,202.685c-3.073,0-3.717-1.411-3.717-2.594a.2.2,0,0,1,.2-.2h.908a.2.2,0,0,1,.2.172c.137.924.546,1.391,2.4,1.391,1.479,0,2.109-.335,2.109-1.12,0-.452-.178-.788-2.477-1.014-1.921-.19-3.11-.615-3.11-2.152,0-1.417,1.194-2.262,3.2-2.262,2.25,0,3.363.781,3.5,2.456a.205.205,0,0,1-.053.156.208.208,0,0,1-.149.065h-.912a.2.2,0,0,1-.2-.159c-.218-.973-.75-1.283-2.193-1.283-1.615,0-1.8.563-1.8.984,0,.511.222.659,2.4.948,2.158.285,3.183.689,3.183,2.206s-1.276,2.406-3.5,2.406"
        transform="translate(-172.958 -160.644)"
        fill="#64c0e8"
      />
      <path
        d="M275.9,184.467a1.353,1.353,0,1,1-1.353-1.354,1.357,1.357,0,0,1,1.353,1.354m-2.488,0a1.135,1.135,0,1,0,1.131-1.131,1.136,1.136,0,0,0-1.131,1.131m.626-.755h.523c.179,0,.53,0,.53.4a.349.349,0,0,1-.287.372c.208.014.222.15.251.344a1.5,1.5,0,0,0,.079.4h-.322c-.008-.071-.058-.458-.058-.48-.021-.086-.05-.129-.157-.129h-.265v.608h-.294Zm.287.666h.236a.2.2,0,0,0,.229-.215c0-.208-.143-.208-.222-.208h-.243Z"
        transform="translate(-225.172 -150.925)"
        fill="#64c0e8"
      />
      <path
        d="M16.688,66.24a.9.9,0,0,0-.45-.781L8.793,61.174a.875.875,0,0,0-.41-.118H8.306a.888.888,0,0,0-.411.118L.45,65.459A.9.9,0,0,0,0,66.24L.016,77.778a.441.441,0,0,0,.224.388.432.432,0,0,0,.447,0l4.425-2.534a.906.906,0,0,0,.45-.78v-5.39a.9.9,0,0,1,.449-.779L7.9,67.6a.9.9,0,0,1,.451-.121.88.88,0,0,1,.447.121l1.883,1.085a.9.9,0,0,1,.45.779v5.39a.908.908,0,0,0,.451.78L16,78.166a.437.437,0,0,0,.449,0,.449.449,0,0,0,.223-.388Z"
        transform="translate(0 -50.323)"
        fill="#64c0e8"
        fillRule="evenodd"
      />
      <path
        d="M243.395.057a.45.45,0,0,0-.669.393V11.875a.315.315,0,0,1-.472.273l-1.865-1.074a.9.9,0,0,0-.9,0l-7.448,4.3a.9.9,0,0,0-.45.779v8.6a.9.9,0,0,0,.449.779l7.448,4.3a.9.9,0,0,0,.9,0l7.449-4.3a.9.9,0,0,0,.45-.779V3.315a.9.9,0,0,0-.461-.785ZM242.7,21.924a.224.224,0,0,1-.112.194l-2.557,1.474a.225.225,0,0,1-.224,0l-2.557-1.474a.224.224,0,0,1-.112-.194V18.971a.225.225,0,0,1,.112-.195l2.557-1.477a.225.225,0,0,1,.225,0l2.557,1.477a.225.225,0,0,1,.112.195Z"
        transform="translate(-190.884 0)"
        fill="#64c0e8"
        fillRule="evenodd"
      />
      <path
        d="M363.88,69.707a.9.9,0,0,0,.447-.778V66.846a.9.9,0,0,0-.448-.778l-7.4-4.3a.9.9,0,0,0-.9,0l-7.445,4.3a.9.9,0,0,0-.45.779v8.6a.9.9,0,0,0,.454.782l7.4,4.216a.9.9,0,0,0,.882,0l4.475-2.487a.45.45,0,0,0,.006-.783l-7.493-4.3a.45.45,0,0,1-.226-.39V69.791a.45.45,0,0,1,.225-.39l2.332-1.344a.449.449,0,0,1,.449,0l2.333,1.344a.449.449,0,0,1,.225.39V71.91a.45.45,0,0,0,.676.389Z"
        transform="translate(-286.568 -50.813)"
        fill="#64c0e8"
        fillRule="evenodd"
      />
      <path
        d="M387.435,105.088a.172.172,0,0,1,.173,0l1.429.824a.173.173,0,0,1,.086.15v1.65a.173.173,0,0,1-.086.15l-1.429.825a.172.172,0,0,1-.173,0l-1.428-.825a.172.172,0,0,1-.086-.15v-1.65a.173.173,0,0,1,.086-.15Z"
        transform="translate(-318.084 -86.597)"
        fill="#64c0e8"
        fillRule="evenodd"
      />
    </g>
    <g transform="translate(20.371 10.929)" clipPath="url(#a)">
      <rect
        width="16.559"
        height="18.889"
        transform="translate(0 0)"
        fill="#64c0e8"
      />
    </g>
    <g transform="translate(20.371 10.929)" clipPath="url(#b)">
      <g transform="translate(0.173 0.027)" opacity="0.66">
        <g clipPath="url(#c)">
          <path
            d="M132.779,66.68l-7.355-4.264a.889.889,0,0,0-.233-.093l-8.314,14.222a.938.938,0,0,0,.251.213l7.4,4.267a.874.874,0,0,0,.711.075l7.767-14.223a.783.783,0,0,0-.226-.2"
            transform="translate(-116.877 -62.324)"
            fill="#64c0e8"
          />
        </g>
      </g>
    </g>
    <g transform="translate(20.888 10.929)" clipPath="url(#d)">
      <rect width="15.972" height="18.823" transform="translate(0 0)"></rect>
    </g>
  </svg>
)

export default NodejsIcon
