import React from 'react';

const NgrxIcon = () => (
  <svg
    id="Group_65"
    data-name="Group 65"
    xmlns="http://www.w3.org/2000/svg"
    width="44.402"
    height="47.625"
    viewBox="0 0 44.906 47.625">
    <g id="ngrx" transform="translate(-44 -11)">
      <path id="Path_220" data-name="Path 220" d="M66.249,11,44,18.723,47.185,48.24,66.249,58.625Z" fill="#64c0e8" />
      <path
        id="Path_221"
        data-name="Path 221"
        d="M499,11l22.249,7.723L518.064,48.24,499,58.625Z"
        transform="translate(-432.847)"
        fill="#bfeafe"
      />
      <path
        id="Path_222"
        data-name="Path 222"
        d="M239.965,204.241l0-.013a5.994,5.994,0,0,1,1.565,4.028,8.96,8.96,0,0,1-1.577,4.914,12.766,12.766,0,0,0,2.8-4.06q.685,6.3-5.107,9.574,1.852-.171,4.907-2.441-3.254,7.835-11.967,8.267a14.588,14.588,0,0,1-9.247-3.373,13.664,13.664,0,0,1-4.758-6.782c-1.38-1.507-1.394-1.669-1.536-2.271s.09-.771.494-1.36a2.214,2.214,0,0,0,.195-1.686,4.251,4.251,0,0,1-.594-2.239,2.195,2.195,0,0,1,.983-1.554,5.05,5.05,0,0,0,1.2-1.253,5.988,5.988,0,0,0,.129-2.047q-.01-1.779,1.975-1.935a5.637,5.637,0,0,0,3.729-2.329,2.365,2.365,0,0,1,1.807-.677,3.8,3.8,0,0,1,2.943,1.232,11.984,11.984,0,0,1,6.533,1.711q4.72,2.8,5.152,6.062-.506,4.282-11.486-.225-5.742,1.626-5.647,7.049,0,4.976,4.805,7.229c-1.561-1.532-2.226-2.82-2-3.887q4.87,5.767,11.088,4.311a5.226,5.226,0,0,1-4.362-1.772q4.2-.1,7.934-3.913a5.54,5.54,0,0,1-4.533,1.3q6.468-5.085,4.578-11.865Zm-7.827-.522a.633.633,0,1,0-.633-.633A.633.633,0,0,0,232.138,203.719Z"
        transform="translate(-162.674 -176.944)"
        fill="#0b1f36"
      />
    </g>
  </svg>
);

export default NgrxIcon;
