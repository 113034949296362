import React from 'react';

const ScreenshotIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.661" height="19.003" viewBox="0 0 31.661 19.003">
    <path
      id="noun-view-934000-FFFFFF"
      d="M111.195,296.813c-1.791-5.178-8.535-9.083-15.686-9.083s-13.9,3.9-15.686,9.083l-.144.418.145.418c1.791,5.178,8.535,9.083,15.686,9.083s13.9-3.9,15.686-9.084l.144-.418Zm-10.716-1.557a4.97,4.97,0,1,1-4.97-4.97A4.975,4.975,0,0,1,100.478,295.256Zm-4.97,8.921c-5.909,0-11.437-2.957-13.111-6.946,1.022-2.435,3.483-4.484,6.587-5.712a7.526,7.526,0,1,0,13.047,0c3.105,1.228,5.565,3.277,6.587,5.712-1.674,3.988-7.2,6.946-13.111,6.946Z"
      transform="translate(-79.678 -287.73)"
    />
  </svg>
);

export default ScreenshotIcon;
