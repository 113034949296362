import React from 'react';

const RxJsIcon = () => (
  <svg
    id="Group_65"
    data-name="Group 65"
    xmlns="http://www.w3.org/2000/svg"
    width="44.906"
    height="47.625"
    viewBox="0 0 44.906 49.625">
    <defs>
      <linearGradient id="linear-gradient" x1="0.119" y1="0.92" x2="0.629" y2="0.366" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#64c0e8" />
        <stop offset="0.401" stopColor="#4bb5e3" />
        <stop offset="0.77" stopColor="#bfeafe" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <radialGradient
        id="radial-gradient"
        cx="0.776"
        cy="0.333"
        r="0.773"
        gradientTransform="matrix(0.734, 0.002, -0.001, 0.836, 0.207, 0.053)"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#e01d84" />
        <stop offset="0" stopColor="#bfeafe" />
        <stop offset="0.139" stopColor="#bfeafe" />
        <stop offset="0.285" stopColor="#bfeafe" />
        <stop offset="0.434" stopColor="#4bb5e3" />
        <stop offset="0.586" stopColor="#4bb5e3" />
        <stop offset="0.739" stopColor="#4bb5e3" />
        <stop offset="0.891" stopColor="#244f6b" />
        <stop offset="1" stopColor="#244f6b" />
      </radialGradient>
    </defs>
    <g id="rxjs-1" transform="translate(-14.986 -16.719)">
      <path
        id="Path_216"
        data-name="Path 216"
        d="M17.769,46.921a20.262,20.262,0,0,1-.7-9.694,33.668,33.668,0,0,1,1.047-4.076,22.055,22.055,0,0,1,11.522-12.57,18.842,18.842,0,0,1,7.675-1.733,10.045,10.045,0,0,0-1.028-.876c-2.171-1.447-5.409-1.924-7.37.114-.59.609-1.086,1.276-1.638,1.885a5.887,5.887,0,0,1-2.266,1.581,11.848,11.848,0,0,1-2.3.362,4.958,4.958,0,0,0-2.266.933,3,3,0,0,0-1.067,2.209c-.076.686-.057,1.39-.1,2.076-.1,2.019-.743,2.59-2.19,3.714a5.886,5.886,0,0,0-1.5,1.714c-1.143,2.019.686,4.114.781,6.152a3.052,3.052,0,0,1-.171,1.238,4.623,4.623,0,0,1-.7,1.086,2.228,2.228,0,0,0-.476,1.638,5.264,5.264,0,0,0,.686,1.657,15.649,15.649,0,0,0,1.962,2.514c.038,0,.076.038.114.076"
        transform="translate(0)"
        fill="#64c0e8"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M61.683,63.3a13.864,13.864,0,0,0,6.247-5.2,14.989,14.989,0,0,0,2.7-11.465c-2.609,5.675-4.99,7.237-4.99,7.237,6.418-9.77.038-15.674.038-15.674,2.609,5.561-.857,12.341-.857,12.341-2.914,6.132-7.047,8.323-7.047,8.323a10.02,10.02,0,0,0,8-2.247c-6.609,7.142-13.77,6.8-13.77,6.8A10.067,10.067,0,0,0,59.531,66.5c-5.9,1.39-11.446-.571-16-4.361A25.093,25.093,0,0,1,41.1,59.852s-.686-.724-.819-.914l-.019-.019c-.1,3.523,3.58,6.8,3.58,6.8a13.7,13.7,0,0,1-7.58-17.369c2.247-6.037,9.046-7.332,9.046-7.332a44.619,44.619,0,0,0,10.38,3.58c10.037,1.676,9.465-3.238,9.465-3.238.1-4.228-6.285-8.723-6.285-8.723-11.37-8.437-21.654-2.99-21.654-2.99a22.055,22.055,0,0,0-11.522,12.57,33.667,33.667,0,0,0-1.047,4.076,21.376,21.376,0,0,0,3.676,15.769A27.265,27.265,0,0,0,41.305,72c11.9,4.038,20.035-1.9,20.035-1.9,7.485-5.142,8.989-11.084,8.989-11.084-6.037,4.723-8.646,4.285-8.646,4.285ZM52.389,35.513a1.028,1.028,0,1,1-1.028,1.028A1.024,1.024,0,0,1,52.389,35.513Z"
        transform="translate(-7.576 -9.065)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_218"
        data-name="Path 218"
        d="M65.1,41.326c.1-4.228-6.285-8.723-6.285-8.723-11.37-8.418-21.654-2.971-21.654-2.971A22.055,22.055,0,0,0,25.641,42.2a35.322,35.322,0,0,0-.971,3.714,21.282,21.282,0,0,0-.3,5.428,20.688,20.688,0,0,0,.781,4.171,21.585,21.585,0,0,0,.838,2.343c-.019-.057-.114-.476-.114-.476a21.48,21.48,0,0,1-.057-7.542,15.368,15.368,0,0,1,3.276-6.818A11.053,11.053,0,0,1,41.3,39.326s1.714.609,2.3.914c.59.286,1.619.724,1.619.724a44.619,44.619,0,0,0,10.38,3.58C65.673,46.24,65.1,41.326,65.1,41.326ZM52.361,37.574a1.028,1.028,0,1,1,1.028-1.028A1.024,1.024,0,0,1,52.361,37.574Z"
        transform="translate(-7.548 -9.051)"
        fill="url(#radial-gradient)"
      />
      <path
        id="Path_219"
        data-name="Path 219"
        d="M93.6,49.439,87.1,47.42a.984.984,0,0,0-.571,0s-3.828.971-3.161,3.066a8.108,8.108,0,0,0,1.486,2.59L92,52.734Z"
        transform="translate(-55.296 -24.82)"
        fill="#bfeafe"
      />
    </g>
  </svg>
);

export default RxJsIcon;
