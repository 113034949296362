/**
 * Component for the Navbar at the top of the page
 */
import React, { useEffect, useState, useRef } from 'react';
import { AppBar, Toolbar, Typography, useScrollTrigger, Box, Slide, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MobileMenu } from '.';
import lottie from 'lottie-web/build/player/lottie_light';
import * as animationData from '../images/toggle.json';
import TextLine from './TextLine';
import { motion, useAnimation } from 'framer-motion';
import PropTypes from 'prop-types';

// Define the props for the component
type scrolltoProps = {
  scrollto: (el: string) => void;
};

export default function Nav({ scrollto }: scrolltoProps) {
  const [mobileMenu, setMobileMenu] = useState(false);

  // animation settings
  const animationContainer = useRef<HTMLButtonElement>(null);
  const anim = useRef<HTMLDivElement | null>(null);
  const controls = useAnimation();
  const trigger = useScrollTrigger();

  // internal media query to determine the display of the toggle button
  const toggleIcon = useMediaQuery('(max-width:960px)');

  // Animation controls (FramerMotion)
  useEffect(() => {
    if (!trigger) {
      controls.start((i) => ({
        opacity: 1,
        transition: { delay: (i + 1) * 0.3 },
      }));
    }
  }, []);

  // if we are viewing the mobile menu - disable the scroll function
  useEffect(() => {
    mobileMenu ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  }, [mobileMenu]);

  // useEffect to control the lottie icon for the toggle button
  useEffect(() => {
    if (animationContainer.current) {
      anim.current = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData,
      });
      if (mobileMenu) {
        console.log('gotoandstop 5');
        anim.current.goToAndStop(5, true);
      }
      return () => anim.current?.destroy();
    }
  }, [toggleIcon]);

  // toggle the mobileMenu boolean
  const mobileMenuToggle = () => {
    setMobileMenu(!mobileMenu);
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    console.log('closeMobileMenu');
    anim.current.setDirection(mobileMenu ? -1 : 1);
    anim.current.play();
  };

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar component="nav" color="transparent" elevation={0}>
        <Toolbar className="appbar">
          <div className="navbar-toggle-icon"></div>
          {toggleIcon || mobileMenu ? (
            <Typography component="div" className="nav-logo" sx={{ display: { xs: 'flex', sm: 'none', md: 'none' } }}>
              <Tooltip title={mobileMenu ? 'Close Menu' : 'Open Menu'} placement="left-end">
                <button
                  onClick={() => {
                    mobileMenuToggle();
                  }}
                  ref={animationContainer}
                />
              </Tooltip>
            </Typography>
          ) : (
            ''
          )}
          {mobileMenu ? (
            <MobileMenu setMobileView={() => mobileMenuToggle()} scrollto={(e) => scrollto(e)} />
          ) : (
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <div className="nav-links">
                <motion.a
                  onClick={() => scrollto('aboutRef')}
                  initial={{ y: -100 }}
                  animate={{ y: 0 }}
                  aria-label="Go to About Me"
                  transition={{ type: 'spring', duration: 0.4, delay: 1 }}>
                  <TextLine text="About Me" divClassName="nav-header-div" textClassName="nav-link-text" />
                </motion.a>
                <motion.a
                  onClick={() => scrollto('projectRef')}
                  initial={{ y: -100 }}
                  animate={{ y: 0 }}
                  aria-label="Go to Projects"
                  transition={{ type: 'spring', duration: 0.4, delay: 1.2 }}>
                  <TextLine text="Projects" divClassName="nav-header-div" textClassName="nav-link-text" />
                </motion.a>
                <motion.a
                  onClick={() => scrollto('footerRef')}
                  initial={{ y: -100 }}
                  animate={{ y: 0 }}
                  aria-label="Go to Contact Me"
                  transition={{ type: 'spring', duration: 0.4, delay: 1.4 }}>
                  <TextLine text="Contact Me" divClassName="nav-header-div" textClassName="nav-link-text" />
                </motion.a>
              </div>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Slide>
  );
}

// function passed to scroll to correct spot
Nav.propTypes = {
  scrollto: PropTypes.func.isRequired,
};
