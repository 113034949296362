import React from 'react'

const AngularIcon = () => (
  <svg
    id="Group_65"
    data-name="Group 65"
    xmlns="http://www.w3.org/2000/svg"
    width="44.906"
    height="47.625"
    viewBox="0 0 44.906 47.625"
  >
    <path
      id="Path_163"
      data-name="Path 163"
      d="M.1,8.591,22.254.7,45.005,8.45,41.322,37.762,22.254,48.322,3.485,37.9Z"
      transform="translate(-0.1 -0.697)"
      fill="#bfeafe"
    />
    <path
      id="Path_164"
      data-name="Path 164"
      d="M148.659,8.45,125.908.7V48.322L144.975,37.78,148.659,8.45Z"
      transform="translate(-103.753 -0.697)"
      fill="#64c0e8"
    />
    <path
      id="Path_165"
      data-name="Path 165"
      d="M61.519,32.274l-13.8,30.715,5.157-.088,2.772-6.929H68.027l3.035,7.017,4.929.088Zm.035,9.841,4.666,9.753H57.449l4.1-9.753Z"
      transform="translate(-39.186 -26.821)"
      fill="#0b1f36"
    />
  </svg>
)

export default AngularIcon
