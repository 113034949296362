/**
 * Component to show all the projects
 * Uses GraphQL to return Markdown data
 */

import React, { useRef, useEffect, useState, RefObject } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, useAnimation } from 'framer-motion';
import Animations from './animations/AnimationsStyles';
import TextLine from './TextLine';
import { styled } from '@mui/material/styles';
import { Box, Paper, Grid, Tooltip } from '@mui/material';
import Icons from './icons/Icons';

// Return all Markdown files with the 'projects' el from newest to oldest
const projData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { el: { eq: "projects" } } }
        sort: { fields: frontmatter___dateadded, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              github
              title
              external
              screenshots
              tech
            }
            html
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges;
};

// For for displaying projects with styled components
const Item = styled(Paper)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

type ProjectsProp = {
  setScrollRef: (el: string, el2: RefObject<HTMLDivElement>) => void;
  setScreenshotView: (el: object) => void;
};

interface PropDataProps {
  node: {
    frontmatter: {
      github: string;
      title: string;
      external: string;
      screenshots: string[];
      tech: string[];
    };
    html: string;
  };
}

interface NodeDataProps {
  github: string;
  title: string;
  external: string;
  screenshots: object;
  tech: string[];
}

const Projects = ({ setScrollRef, setScreenshotView }: ProjectsProp) => {
  // set the component to only show 6 projects at a time
  const [projectNoInd, setProjectNoInd] = useState(6);
  const [projects, setProjects] = useState(projData());

  const controls = useAnimation();
  const controls_delay = useAnimation();
  const rootRef = useRef<HTMLDivElement>(null);

  // set variable tyo first 6 projects
  const displayProjectArr = projects.slice(0, projectNoInd);

  const showHandler = (moreOrLess: boolean) => {
    moreOrLess ? setProjectNoInd(projectNoInd + 6) : setProjectNoInd(projectNoInd - 6);
  };

  // set the scroll ref for this component
  useEffect(() => {
    setScrollRef('projectRef', rootRef);
  }, []);

  // determine the link to be added to the header for each projects (external or repo)
  const setHeaderLink = (nodeData: NodeDataProps) => {
    if (nodeData.external) {
      return nodeData.external;
    } else {
      return nodeData.github;
    }
  };

  // how to animate each project
  useEffect(() => {
    controls_delay.start((i) => ({
      opacity: 1,
      transition: { delay: (i + 7) * 0.3 },
    }));
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: i * 0.3 },
    }));
  }, [projectNoInd]);

  return (
    <>
      <motion.div
        id="projects"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.1 }}
        ref={rootRef}>
        <motion.div className="section project" variants={Animations.animeSettings('sideEntry', 'onScroll')}>
          <div className="panel-left section-header">
            <TextLine text="Projects" divClassName="section-header-div" textClassName="nav-link-text" />
          </div>
          <div className="panel-right about-text">
            <Box className="project-grid" sx={{ flexGrow: 1 }}>
              <Grid container spacing={3}>
                {displayProjectArr.map((projectData: PropDataProps, i: number) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    key={i}
                    custom={i - (projectNoInd - 6)}
                    initial={Animations.animeSettings('fadeIn').initial}
                    animate={projectNoInd > 6 ? controls : controls_delay}
                    className="grid-item"
                    component={motion.div}>
                    <Item className="project-cell">
                      <div>
                        {projectData.node.frontmatter.screenshots.length > 0 ? (
                          <a role="link" onClick={() => setScreenshotView(projectData.node.frontmatter.screenshots)}>
                            <div className="project-header">{projectData.node.frontmatter.title}</div>
                          </a>
                        ) : (
                          <a
                            href={setHeaderLink(projectData.node.frontmatter)}
                            role="link"
                            target="_blank"
                            rel="noreferrer">
                            <div className="project-header">{projectData.node.frontmatter.title}</div>
                          </a>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: projectData.node.html }} />
                        <div className="project-tech">{projectData.node.frontmatter.tech}</div>
                      </div>
                      <div className="icons">
                        {projectData.node.frontmatter.github && (
                          <Tooltip title="View Repo" placement="top">
                            <a href={projectData.node.frontmatter.github} role="link" target="_blank" rel="noreferrer">
                              <Icons icon="github" />
                            </a>
                          </Tooltip>
                        )}
                        {projectData.node.frontmatter.external && (
                          <Tooltip title="View Project" placement="top">
                            <a
                              href={projectData.node.frontmatter.external}
                              role="link"
                              target="_blank"
                              rel="noreferrer">
                              <Icons icon="external" />
                            </a>
                          </Tooltip>
                        )}
                        {projectData.node.frontmatter.screenshots.length > 0 && (
                          <Tooltip title="View Screenshots" placement="top">
                            <a role="link" onClick={() => setScreenshotView(projectData.node.frontmatter.screenshots)}>
                              <Icons icon="screenshot" />
                            </a>
                          </Tooltip>
                        )}
                      </div>
                    </Item>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <div className="button-div">
              {projectNoInd < projects.length ? (
                <button onClick={() => showHandler(true)}>SEE MORE</button>
              ) : (
                <button onClick={() => showHandler(false)}>SEE LESS</button>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Projects;
