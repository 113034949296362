/**
 * Component to display the About Me section
 * Uses GraphQL to return Markdown data
 */
import React, { useEffect, useRef, useState, RefObject } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Work from './Work';
import MobileWork from './MobileWork';
import Icons from './icons/Icons';
import TextLine from './TextLine';
import { motion, useAnimation } from 'framer-motion';
import Animations from './animations/AnimationsStyles';

// Return all Markdown files with the 'aboutme' el
const aboutData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { el: { eq: "aboutme" } } }) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges[0].node;
};

type AboutProps = {
  setScrollRef: (el: string, el2: RefObject<HTMLDivElement>) => void;
};

interface aboutDataProps {
  html: string;
}

type accordionIndexProp = {
  accordionIndex: number;
  setAccordionIndex: (el: number) => null;
  // setExpanded: (val: string) => null;
};

const About = ({ setScrollRef }: AboutProps) => {
  // set workData display to opening tab
  const [accordionIndex, setAccordionIndex] = useState(0);

  // call the useStaticQuery to retrieve about me
  const aboutText: aboutDataProps = aboutData();

  // set the scrollRef using the function passed as props
  const rootRef = useRef<HTMLDivElement>(null);

  // internal media query to determine the display of Work or MobileWork
  const mobileSize = useMediaQuery('(max-width:774px)');

  const controls = useAnimation();
  useEffect(() => {
    setScrollRef('aboutRef', rootRef);
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: (i + 2.5) * 0.3 },
    }));
  }, []);

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      id="about"
      ref={rootRef}>
      <motion.div className="section about" variants={Animations.animeSettings('sideEntry', 'onScroll')}>
        <div className="panel-left section-header">
          <TextLine text="About Me" divClassName="section-header-div" textClassName="nav-link-text" />
        </div>
        <div className="panel-right about-text">
          <div dangerouslySetInnerHTML={{ __html: aboutText.html }} />
          <ul className="icons">
            <motion.li key="nodejs" custom={1} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="nodejs" />
            </motion.li>
            <motion.li
              key="bootstrap"
              custom={2}
              initial={Animations.animeSettings('fadeIn').initial}
              animate={controls}>
              <Icons icon="bootstrap" />
            </motion.li>
            <motion.li
              key="javascript"
              custom={3}
              initial={Animations.animeSettings('fadeIn').initial}
              animate={controls}>
              <Icons icon="javascript" />
            </motion.li>
            <motion.li key="angular" custom={4} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="angular" />
            </motion.li>
            <motion.li key="html" custom={5} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="html" />
            </motion.li>
            <motion.li key="react" custom={6} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="react" />
            </motion.li>
            <motion.li key="ngrx" custom={7} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="ngrx" />
            </motion.li>
            <motion.li key="rxjs" custom={8} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Icons icon="rxjs" />
            </motion.li>
          </ul>
          {/* display component based on internal media query */}
          {mobileSize ? (
            <MobileWork accordionIndex={accordionIndex} setAccordionIndex={(v) => setAccordionIndex(v)} />
          ) : (
            <Work accordionIndex={accordionIndex} setAccordionIndex={(v) => setAccordionIndex(v)} />
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default About;
