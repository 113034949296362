// Types of animations used on the site

interface initialProp {
  opacity?: number;
  y?: number;
  x?: number;
}
interface animateProp {
  opacity?: number;
  y?: number;
  x?: number;
  transition: {
    duration: number;
    ease?: string;
    type?: string;
  };
}

interface animationObjectProp {
  sideEntry: {
    initial: initialProp;
    animate: animateProp;
  };
  popUpAnimateObj: {
    initial: initialProp;
    animate: animateProp;
  };
  navButtonEntry: {
    initial: initialProp;
    animate: animateProp;
  };
  fadeDown: {
    initial: initialProp;
    animate: animateProp;
  };
  fadeIn: {
    initial: initialProp;
    animate: animateProp;
  };
  popUpTopRight: {
    initial: initialProp;
    animate: animateProp;
  };
  pop: {
    initial: initialProp;
    animate: animateProp;
  };
}

const animation: animationObjectProp = {
  sideEntry: {
    initial: {
      opacity: 0,
      x: -50,
    },

    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 2,
        ease: 'easeOut',
      },
    },
  },

  popUpAnimateObj: {
    initial: {
      opacity: 0,
      y: -1000,
    },

    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  },

  navButtonEntry: {
    initial: {
      y: -100,
    },
    animate: {
      y: 0,
      transition: {
        type: 'spring',
        duration: 0.4,
      },
    },
  },

  fadeDown: {
    initial: {
      opacity: 0,
      y: -100,
    },

    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: 'easeOut',
      },
    },
  },

  fadeIn: {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut',
      },
    },
  },

  popUpTopRight: {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,
      transition: {
        duration: 0.2,
        ease: 'easeOut',
      },
    },
  },

  pop: {
    initial: {
      opacity: 0,
    },

    animate: {
      opacity: 1,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
  },
};

type animeSettingsProp = {
  offscreen?: initialProp;
  initial?: initialProp;
  onscreen?: animateProp;
  animate?: animateProp;
};

// Object returned to set up Framer-Motion
const animeSettings = (animeType: string, animeNeed?: string): animeSettingsProp => {
  if (animeNeed === 'onScroll') {
    return {
      offscreen: animation[animeType as keyof animationObjectProp].initial,
      onscreen: animation[animeType as keyof animationObjectProp].animate,
    };
  } else {
    return {
      initial: animation[animeType as keyof animationObjectProp].initial,
      animate: animation[animeType as keyof animationObjectProp].animate,
    };
  }
};

export default { animeSettings };
