import React from 'react'

const JavascriptIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 47.623 47.624"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="javascript_icon"
  >
    <g transform="translate(-0.001 -0.001)">
      <path
        d="M-3496.1-16590.379h-47.621V-16638h47.623v47.623h0Zm-31.482-10.025c-.651.4-1.309.8-1.946,1.189l-.284.174-.988.605-.406.248a7.174,7.174,0,0,0,2.626,3.006,7.916,7.916,0,0,0,4.071,1.049,10.218,10.218,0,0,0,2.292-.264,5.646,5.646,0,0,0,3.6-2.818c.865-1.6.834-3.512.8-5.541v-.008c-.006-.354-.011-.719-.012-1.086.014-2.746.009-5.5,0-8.168v-.256c0-1.262,0-2.561,0-3.84h-4.455c0,1.855,0,3.705-.008,5.758,0,1.68-.008,3.77-.008,5.758,0,.484,0,.959.01,1.418v.023c.019,1.82.036,3.391-.281,3.941a2.057,2.057,0,0,1-1.985,1.1,3.634,3.634,0,0,1-1.126-.182,3.814,3.814,0,0,1-1.645-1.707l-.023-.041a1.859,1.859,0,0,0-.227-.363Zm15.8-.389c-1.783,1.031-1.783,1.033-3.616,2.088a6.665,6.665,0,0,0,1.308,1.85l0,.006q.1.108.206.229l.1.115a9.246,9.246,0,0,0,6.5,2.363,9.155,9.155,0,0,0,4.429-1.049,5.492,5.492,0,0,0,2.707-3.281,6.625,6.625,0,0,0,.142-3.264c-.393-2.459-2.175-4.164-5.958-5.7-.291-.133-.588-.26-.84-.367-1.2-.51-2.338-.99-2.731-1.887a2.462,2.462,0,0,1-.083-1.391,2.05,2.05,0,0,1,2.14-1.428,3,3,0,0,1,.875.129,3.326,3.326,0,0,1,1.938,1.8c1.667-1.082,2-1.293,2.805-1.807l.07-.043.611-.389a8.625,8.625,0,0,0-1.152-1.537,6.55,6.55,0,0,0-5.326-2.039c-.094,0-.189,0-.286,0l-.438.057-.326.043-.019,0-.62.078a6.286,6.286,0,0,0-3.378,1.99,5.909,5.909,0,0,0-1.311,4.688,5.891,5.891,0,0,0,2.443,4.189,17.528,17.528,0,0,0,3.729,1.951l.009,0c1.69.719,3.15,1.338,3.444,2.436a2.137,2.137,0,0,1-.348,1.873,3.419,3.419,0,0,1-2.72.986,7.02,7.02,0,0,1-.828-.053,5.1,5.1,0,0,1-3.478-2.643Z"
        transform="translate(3543.727 16638.002)"
      />
    </g>
  </svg>
)

export default JavascriptIcon
