/**
 * Component to display the footer section at the bottom of the page
 * This component makes the contact section disappear when footer enters the viewport
 */
import React, { useEffect, useRef, RefObject } from 'react';
import { motion } from 'framer-motion';
import Animations from './animations/AnimationsStyles';
import Tooltip from '@material-ui/core/Tooltip';
import useOnScreen from './custom_hooks/useOnScreen';
import TextLine from './TextLine';
import Icons from './icons/Icons';

type FooterProps = {
  setScrollRef: (el: string, el2: RefObject<HTMLDivElement>) => void;
  seeFooter: (el: boolean) => void;
};

//function Employee({name, age, country}: EmployeeProps) {

const Footer = ({ setScrollRef, seeFooter }: FooterProps) => {
  // Interaction Observer (custom hook) called update value in parent app
  // to make contact component disappear when at bottom of the page
  const rootRef = useRef<HTMLDivElement | null>(null);
  const onScreen = useOnScreen(rootRef, '-200px');
  useEffect(() => {
    setScrollRef('footerRef', rootRef);
    if (onScreen) {
      seeFooter(true);
    } else {
      seeFooter(false);
    }
  }, [onScreen]);

  return (
    <motion.div
      ref={rootRef}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.1 }}
      id="contact">
      <motion.div className="section footer" variants={Animations.animeSettings('sideEntry', 'onScroll')}>
        <div className="panel-left section-header" id="test">
          <TextLine text="Contact Me" divClassName="section-header-div" textClassName="nav-link-text" />
        </div>
        <div className="panel-right section-text">
          <div>
            Feel free to <span className="bold-para">reach out</span>... I would <span className="bold-para">love</span>{' '}
            to hear from you!
          </div>
          <div className="icons">
            <a
              href="https://www.linkedin.com/in/simon-bertoli-5a73893/"
              target="_blank"
              rel="noreferrer"
              aria-label="View my LinkedIn page">
              <Tooltip title="Get in touch via LinkedIn" placement="top">
                <button>
                  <Icons icon="linkedIn" />
                </button>
              </Tooltip>
            </a>
            <a href="mailto:simonbertoli@yahoo.com.au" aria-label="Email Me">
              <Tooltip title="Email me!" placement="top">
                <button>
                  <Icons icon="email" />
                </button>
              </Tooltip>
            </a>
            <a href={'/resume.pdf'} target="_blank" rel="noreferrer" aria-label="View my GitHub page">
              <Tooltip title="Download my 1-Page CV" placement="top">
                <button aria-label="Download my resume">
                  <Icons icon="resume" />
                </button>
              </Tooltip>
            </a>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Footer;
