/**
 * Component that displays screenshots of projects
 */

import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Box, Grid } from '@mui/material';
import ImgLoader from './ImgLoader';
import Animations from './animations/AnimationsStyles';
import Icons from './icons/Icons';
import PropTypes from 'prop-types';
import getWindowSize from './custom_hooks/getWindowSize';

type ScreenshotOverlayProps = {
  imagePathArr: string[];
  setScreenshotView: (el: object) => void;
};
const ScreenshotOverlay = ({ setScreenshotView, imagePathArr }: ScreenshotOverlayProps) => {
  console.log('🚀 ~ ScreenshotOverlay ~ imagePathArr:', imagePathArr);
  // hook to determine and monitor screensize
  let windowSize = getWindowSize();

  // Animation controls (FramerMotion)
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: i * 0.3 },
    }));
  }, []);

  return (
    <>
      <motion.div
        className="pop-up-bg"
        initial={{ y: -1000, x: 1000 }}
        animate={{
          y: -800,
          x: -100,
          rotate: -45,
          width: windowSize.innerHeight * 2.5,
          height: windowSize.innerHeight * 3,
        }}
        transition={{ duration: 0.35 }}
        exit={{ opacity: 0, y: -1000, transition: { duration: 0.5 } }}
      />
      <div className="screenshots">
        <span onClick={setScreenshotView}>
          <motion.div custom={0} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
            <Icons icon="close" />
          </motion.div>
        </span>
        <Box className="gallery">
          <Grid className="gallery-container" container spacing={2}>
            {imagePathArr.map((imagePath, i) => (
              <Grid
                key={i}
                custom={i + 1}
                initial={Animations.animeSettings('fadeIn').initial}
                animate={controls}
                component={motion.div}>
                <ImgLoader imagePath={imagePath} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

/**
 * Function and array passed to component
 * To close the overlay and process the images to display
 */
ScreenshotOverlay.propTypes = {
  imagePathArr: PropTypes.array.isRequired,
  setScreenshotView: PropTypes.func.isRequired,
};

export default ScreenshotOverlay;
