import React, { ReactElement } from 'react';
import {
  LinkedinIcon,
  CodepenIcon,
  GithubIcon,
  ResumeIcon,
  NodejsIcon,
  BootstrapIcon,
  JavascriptIcon,
  HtmlIcon,
  ReactIcon,
  EmailIcon,
  AngularIcon,
  ScreenshotIcon,
  ExternalIcon,
  NgrxIcon,
  ReduxIcon,
  RxJsIcon,
  Close,
} from '.';

type iconProp = {
  icon: string;
};

const Icons = ({ icon }: iconProp): ReactElement => {
  switch (icon) {
    case 'github':
      return <GithubIcon />;
    case 'codepen':
      return <CodepenIcon />;
    case 'linkedIn':
      return <LinkedinIcon />;
    case 'resume':
      return <ResumeIcon />;
    case 'nodejs':
      return <NodejsIcon />;
    case 'bootstrap':
      return <BootstrapIcon />;
    case 'javascript':
      return <JavascriptIcon />;
    case 'html':
      return <HtmlIcon />;
    case 'react':
      return <ReactIcon />;
    case 'angular':
      return <AngularIcon />;
    case 'email':
      return <EmailIcon />;
    case 'close':
      return <Close />;
    case 'screenshot':
      return <ScreenshotIcon />;
    case 'external':
      return <ExternalIcon />;
    case 'ngrx':
      return <NgrxIcon />;
    case 'redux':
      return <ReduxIcon />;
    case 'rxjs':
      return <RxJsIcon />;
    default:
      return <div />;
  }
};

export default Icons;
