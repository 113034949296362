/**
 * Image Loader component for the screenshot overlay component
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

type imagePathProps = {
  imagePath: string;
};

// set up the img tag and useEffect to load image
const ImgLoader = ({ imagePath }: imagePathProps) => {
  const images = imagePath.split(' - ');
  const [currentImage, setCurrentImage] = useState(images[1]);
  const loadingStyleClass = currentImage === images[1] ? 'loading' : 'loaded';
  useEffect(() => {
    const loadingImage = new Image();
    loadingImage.src = images[0];
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
    };
  }, []);
  return <img src={currentImage} className={loadingStyleClass} />;
};

export default ImgLoader;
