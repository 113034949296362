/**
 * Component that shows brief work experience
 * Uses GraphQL to return Markdown data
 */

import React, { ChangeEvent } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Tabs, Tab, Box, Typography } from '@material-ui/core';

// Return all Markdown files with the 'jobs' el from newest to oldest
const workData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { el: { eq: "jobs" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              company
              date
              title
            }
            html
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges;
};

type workDataProps = {
  node: {
    frontmatter: {
      company: string;
      date: string;
      title: string;
    };
    html: string;
  };
};

type accordionIndexProp = {
  accordionIndex: number;
  setAccordionIndex: (el: number) => void;
};

type TapPaneProps = {
  className: string;
  children: React.ReactNode;
  index: number;
  value: number;
};

const Work = ({ accordionIndex, setAccordionIndex }: accordionIndexProp) => {
  // Function to display selected Tab
  const handleChange = (e: ChangeEvent<unknown>, newValue: number): void => {
    setAccordionIndex(newValue);
  };

  // Format the tabpanel with properties from parameter
  const TabPanel = ({ children, value, index, ...other }: TapPaneProps) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  // call the useStaticQuery to retrieve work data
  const workInfo = workData();
  return (
    <>
      <hr></hr>
      <Box className="work-tab" sx={{ flexGrow: 1, display: 'flex' }}>
        <Tabs
          classes={{ indicator: 'indicator' }}
          className="tab-column"
          orientation="vertical"
          variant="scrollable"
          value={accordionIndex}
          onChange={handleChange}
          aria-label="Work History">
          {workInfo.map((workData: workDataProps, i: number) => (
            <Tab
              className="tab"
              label={workData.node.frontmatter.company}
              aria-controls={`vertical-tab-${i}`}
              key={`workdata-tab-${i}`}
            />
          ))}
        </Tabs>
        {workInfo.map((workData: workDataProps, i: number) => (
          <TabPanel className="tab-content" value={accordionIndex} index={i} key={`workdata-tab-${i}`}>
            <span className="title">{workData.node.frontmatter.title}</span>
            <span className="date"> {workData.node.frontmatter.date}</span>
            <span dangerouslySetInnerHTML={{ __html: workData.node.html }} />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

export default Work;
