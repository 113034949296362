/**
 * Component to display the headings and navbar in a certain style
 */
import React, { FC } from 'react';

interface TextLineProps {
  text: string;
  divClassName: string;
  textClassName: string;
}

const TextLine = ({ text, divClassName, textClassName }: TextLineProps) => {
  return (
    <div className={divClassName}>
      <div className={textClassName}>{text}</div>
      <span />
    </div>
  );
};

export default TextLine;
