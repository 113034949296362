import React from 'react';

const ExternalIcon = () => (
  <svg id="noun-link-3028967-FFFFFF_1_" width="31.869" height="31.869" viewBox="0 0 31.869 31.869">
    <path
      id="Path_182"
      data-name="Path 182"
      d="M55.556,220.481a1.274,1.274,0,0,0-1.275,1.275v9.091a4.567,4.567,0,0,1-4.562,4.562H37.14a4.567,4.567,0,0,1-4.562-4.562V218.3a4.567,4.567,0,0,1,4.562-4.562H46.23a1.275,1.275,0,0,0,0-2.55H37.139a7.1,7.1,0,0,0-7.145,7.112v12.58a7.123,7.123,0,0,0,7.145,7.112h12.58a7.151,7.151,0,0,0,7.145-7.145V221.79a1.355,1.355,0,0,0-1.308-1.308Z"
      transform="translate(-29.994 -206.123)"
    />
    <path
      id="Path_183"
      data-name="Path 183"
      d="M545.547,30h-9.326a1.275,1.275,0,0,0,0,2.55h6.24L529.277,45.733a1.275,1.275,0,0,0,0,1.812,1.3,1.3,0,0,0,1.811,0l13.184-13.184V40.6a1.275,1.275,0,1,0,2.55,0V31.275A1.274,1.274,0,0,0,545.547,30Z"
      transform="translate(-514.953 -30)"
    />
  </svg>
);

export default ExternalIcon;
