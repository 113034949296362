/**
 * Main component
 */

import React, { useState, useEffect, RefObject } from 'react';
import { Nav, Contact, Hero, About, Projects, Footer, Loader, ScreenshotOverlay } from '../components';
import { motion, useAnimation } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Animations from '../components/animations/AnimationsStyles';

import * as Scroll from 'react-scroll';

import '@fontsource/ibm-plex-sans/100.css';
import '@fontsource/ibm-plex-sans/200.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/700.css';
import '@fontsource/ibm-plex-mono/400.css';
import './index.scss';

interface ScrollRefsProp {
  aboutRef: string | RefObject<HTMLDivElement>;
  projectRef: string | RefObject<HTMLDivElement>;
  footerRef: string | RefObject<HTMLDivElement>;
}

// markup
export default function IndexPage() {
  const [seeFooter, setSeeFooter] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [screenshots, setScreenshots] = useState(false);
  const [imagePath, setImagePath] = useState<string[]>([]);
  const [scrollRefs, setScrollRefs] = useState<ScrollRefsProp>({
    aboutRef: '',
    projectRef: '',
    footerRef: '',
  });
  const controls = useAnimation();

  // Set up all the scroll references once they have loaded
  const handleScrollRefSet = (ref: string | RefObject<HTMLDivElement>, refEl: RefObject<HTMLDivElement>) => {
    if (scrollRefs[ref as keyof ScrollRefsProp] === '') {
      if (ref === 'aboutRef') {
        setScrollRefs((prevState) => ({
          ...prevState,
          aboutRef: refEl,
        }));
      } else if (ref === 'projectRef') {
        setScrollRefs((prevState) => ({
          ...prevState,
          projectRef: refEl,
        }));
      } else if (ref === 'footerRef') {
        setScrollRefs((prevState) => ({
          ...prevState,
          footerRef: refEl,
        }));
      }
    }
  };

  // scroll functions
  const scroll = Scroll.animateScroll;
  const scrollTo = (ref: string) => {
    // if (typeof ref === RefObject<HTMLDivElement>)
    scroll.scrollTo(scrollRefs[ref as keyof ScrollRefsProp].current.offsetTop);
  };

  // animation functions
  controls.start((i) => ({
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      delay: i * 0.2,
      default: {
        duration: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      },
      scale: {
        type: 'spring',
        damping: 5,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  }));

  // if overlay triggered, disable scrolling
  useEffect(() => {
    screenshots ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  }, [screenshots]);

  // if we are viewing screenshots, toggle state and image array
  const screenshotToggle = (imagePathArr: string[]) => {
    console.log('🚀 ~ screenshotToggle ~ imagePathArr:', imagePathArr, typeof imagePathArr);
    setImagePath(imagePathArr);
    setScreenshots(!screenshots);
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}>
        <title>simonbertoli.com</title>
      </Helmet>
      {!hasLoaded ? (
        <Loader finishedLoading={() => setHasLoaded(true)} />
      ) : (
        <>
          <motion.div
            initial={{ y: -1000, opacity: 0 }}
            animate={{ y: -3, opacity: 1 }}
            transition={{ type: 'spring', duration: 0.5, delay: 0.5 }}>
            <div className="logo" />
          </motion.div>
          {screenshots && <ScreenshotOverlay imagePathArr={imagePath} setScreenshotView={(f) => screenshotToggle(f)} />}
          <motion.div custom={1} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
            <Nav scrollto={(e) => scrollTo(e)} />
          </motion.div>
          <motion.div custom={2} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
            <Contact isVisible={seeFooter} />
          </motion.div>
          <main>
            <motion.div custom={5} initial={Animations.animeSettings('fadeIn').initial} animate={controls}>
              <Hero scrollto={(e) => scrollTo(e)} />
            </motion.div>
            <hr />
            <About setScrollRef={handleScrollRefSet} />
            <hr />
            <Projects setScrollRef={handleScrollRefSet} setScreenshotView={(f) => screenshotToggle(f)} />
            <hr />
            <Footer setScrollRef={handleScrollRefSet} seeFooter={(v) => setSeeFooter(v)} />
          </main>
        </>
      )}
    </>
  );
}
