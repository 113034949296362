import React from 'react'

const BootstrapIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59.782 47.624"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="bootstrap_icon"
  >
    <path d="M12.417,0A5.956,5.956,0,0,0,6.595,6.224,33.47,33.47,0,0,1,5.548,16.649C4.53,19.943,2.81,22.031,0,22.3v3.026c2.81.269,4.53,2.355,5.548,5.651A33.467,33.467,0,0,1,6.595,41.4a5.957,5.957,0,0,0,5.823,6.224H47.37A5.956,5.956,0,0,0,53.192,41.4a33.47,33.47,0,0,1,1.047-10.425c1.018-3.3,2.734-5.383,5.544-5.651V22.3c-2.81-.269-4.526-2.355-5.544-5.651A33.465,33.465,0,0,1,53.192,6.224,5.956,5.956,0,0,0,47.37,0H12.417ZM40.531,29.316c0,4.458-3.326,7.162-8.844,7.162H22.292a1.013,1.013,0,0,1-1.013-1.013V12.159a1.013,1.013,0,0,1,1.013-1.013h9.341c4.6,0,7.622,2.493,7.622,6.32a5.659,5.659,0,0,1-4.62,5.512v.14c3.523.386,5.9,2.827,5.9,6.2ZM30.662,14.358H25.305v7.566h4.512c3.488,0,5.411-1.4,5.411-3.915,0-2.353-1.654-3.652-4.566-3.652ZM25.305,24.927v8.338h5.554c3.631,0,5.555-1.457,5.555-4.2s-1.977-4.143-5.788-4.143h-5.32Z" />
  </svg>
)

export default BootstrapIcon
