import React from 'react';

const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65.95" height="65.991" viewBox="0 0 65.95 65.991">
    <g id="Group_100" data-name="Group 100" transform="translate(-497.025 -78.984)">
      <line
        id="Line_23"
        data-name="Line 23"
        x2="61"
        y2="61"
        transform="translate(499.5 81.5)"
        fill="none"
        stroke="#000"
        strokeWidth="7"
      />
      <line
        id="Line_24"
        data-name="Line 24"
        y1="59"
        x2="61"
        transform="translate(499.5 81.5)"
        fill="none"
        stroke="#000"
        strokeWidth="7"
      />
    </g>
  </svg>
);

export default Close;
