import React from 'react'

const ReactIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46.059 48.991"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="react_icon"
  >
    <g transform="translate(0.5 0.683)">
      <circle
        cx="4.199"
        cy="4.199"
        r="4.199"
        transform="translate(18.331 19.614)"
        fill="#64c0e8"
      />
      <g transform="translate(0 0)">
        <ellipse
          cx="22.529"
          cy="8.602"
          rx="22.529"
          ry="8.602"
          transform="translate(0 15.21)"
          fill="none"
          stroke="#64c0e8"
          strokeWidth="1"
        />
        <ellipse
          cx="22.529"
          cy="8.602"
          rx="22.529"
          ry="8.602"
          transform="translate(18.714 0) rotate(60)"
          fill="none"
          stroke="#64c0e8"
          strokeWidth="1"
        />
        <ellipse
          cx="22.529"
          cy="8.602"
          rx="22.529"
          ry="8.602"
          transform="translate(41.244 8.602) rotate(120)"
          fill="none"
          stroke="#64c0e8"
          strokeWidth="1"
        />
      </g>
    </g>{' '}
  </svg>
)

export default ReactIcon
