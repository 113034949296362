/**
 * Component that shows brief work experience
 * Uses GraphQL to return Markdown data
 */

import React, { useState, ChangeEvent } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const workData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { el: { eq: "jobs" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              company
              date
              title
            }
            html
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges;
};

type workDataProps = {
  node: {
    frontmatter: {
      company: string;
      date: string;
      title: string;
    };
    html: string;
  };
};

type accordionIndexProp = {
  accordionIndex: number;
  setAccordionIndex: (el: number) => void;
};

const MobileWork = ({ accordionIndex, setAccordionIndex }: accordionIndexProp) => {
  const [expanded, setExpanded] = useState('panel' + accordionIndex);

  // call the useStaticQuery to retrieve work data
  const workInfo = workData();

  // Function to display selected Tab
  const handleChange = (i: number) => (event: ChangeEvent<unknown>, newExpanded: boolean) => {
    setAccordionIndex(i);
    setExpanded(newExpanded ? 'panel' + i : '');
  };

  return (
    <div>
      <hr></hr>
      {workInfo.map((workData: workDataProps, i: number) => (
        <Accordion className="work-accordion" expanded={expanded === `panel${i}`} onChange={handleChange(i)} key={i}>
          <AccordionSummary
            expandIcon={<ArrowRightIcon sx={{ fontSize: '3rem' }} />}
            aria-controls="panel1d-content"
            id="panel1d-header"
            className="company">
            <div>{workData.node.frontmatter.company}</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="work-content">
              <p className="title">{workData.node.frontmatter.title}</p>
              <p className="date">{workData.node.frontmatter.date}</p>
              <p dangerouslySetInnerHTML={{ __html: workData.node.html }} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default MobileWork;
