/**
 * Component that displays the Mobile menu overlay
 * called then navbar toggle is visible
 */
import React, { useEffect } from 'react';
import TextLine from './TextLine';
import { motion, useAnimation } from 'framer-motion';
import Animations from './animations/AnimationsStyles';
import getWindowSize from './custom_hooks/getWindowSize';

type MobileMenuProp = {
  setMobileView(): void;
  scrollto: (el: string) => void;
};

const MobileMenu = ({ setMobileView, scrollto }: MobileMenuProp) => {
  // call customHook to determine current screen size
  let windowSize = getWindowSize();
  // set up animation for display
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: i * 0.3 },
    }));
  }, []);

  /**
   * Function called when user closes the menu
   * Passed optional parameter that scrolls to section if user has selected a menu item
   */
  const closeMenu = (gotoSection: string) => {
    setMobileView();
    scrollto(gotoSection);
  };

  return (
    <>
      <motion.div
        className="pop-up-bg"
        initial={{ y: -1000, x: 1000 }}
        animate={{
          y: -400,
          x: -100,
          rotate: -45,
          width: windowSize.innerHeight * 1.6,
          height: windowSize.innerHeight * 1.75,
        }}
        transition={{ duration: 0.35 }}
        exit={{ opacity: 0, y: -1000, transition: { duration: 0.5 } }}
      />
      <div className="mobile-menu">
        <div className="nav-links">
          <motion.a
            key={1}
            onClick={() => closeMenu('aboutRef')}
            custom={1}
            initial={Animations.animeSettings('fadeIn').initial}
            animate={controls}>
            <TextLine text="About Me" divClassName="nav-header-div" textClassName="nav-link-text" />
          </motion.a>
          <motion.a
            onClick={() => closeMenu('projectRef')}
            key={2}
            custom={2}
            initial={Animations.animeSettings('fadeIn').initial}
            animate={controls}>
            <TextLine text="Projects" divClassName="nav-header-div" textClassName="nav-link-text" />
          </motion.a>
          <motion.a
            key={3}
            onClick={() => closeMenu('footerRef')}
            custom={3}
            initial={Animations.animeSettings('fadeIn').initial}
            animate={controls}>
            <TextLine text="Contact Me" divClassName="nav-header-div" textClassName="nav-link-text" />
          </motion.a>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
