import React from 'react'

const HtmlIcon = () => (
  <svg
    id="Group_63"
    data-name="Group 63"
    xmlns="http://www.w3.org/2000/svg"
    width="41.723"
    height="47.623"
    viewBox="0 0 41.723 47.623"
  >
    <path
      id="Path_157"
      data-name="Path 157"
      d="M3.494,42.7,0,0,41.723.086,38.029,42.7l-17.01,4.925Z"
      transform="translate(0 0)"
      fill="#64c0e8"
    />
    <path
      id="Path_158"
      data-name="Path 158"
      d="M62.468,51.518V12.085l17.182.057L76.586,47.423Z"
      transform="translate(-41.449 -8.019)"
      fill="#bfeafe"
    />
    <path
      id="Path_159"
      data-name="Path 159"
      d="M48.328,31.084l.487-5.212H22.383l1.46,15.98H42.085l-.715,6.815-5.842,1.575L29.6,48.524l-.315-4.066H24.044l.716,8.3,10.767,3.007L46.38,52.762l1.46-16.208H28.683l-.544-5.47Z"
      transform="translate(-14.852 -17.165)"
      fill="#0b1f36"
    />
  </svg>
)

export default HtmlIcon
