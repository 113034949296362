import React from 'react'

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 58.875 62.25"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="email_icon"
  >
    <path
      d="M50.4,90.075H34.65a33.8,33.8,0,0,1-12.713-2.25,26.333,26.333,0,0,1-9.45-6.337A27.462,27.462,0,0,1,6.6,71.738,37.093,37.093,0,0,1,4.575,59.175a36.611,36.611,0,0,1,2.1-12.6,28.663,28.663,0,0,1,6-9.937,27.129,27.129,0,0,1,9.45-6.487A32.142,32.142,0,0,1,34.65,27.825a33.443,33.443,0,0,1,11.775,1.987,26.192,26.192,0,0,1,9.112,5.625,24.668,24.668,0,0,1,5.85,8.85A31.245,31.245,0,0,1,63.45,55.875q0,9-3.788,13.988A12.182,12.182,0,0,1,49.425,74.85a10.565,10.565,0,0,1-6.6-1.95,6.771,6.771,0,0,1-2.775-5.1h-.375a9.584,9.584,0,0,1-2.812,5.025A8.334,8.334,0,0,1,31.05,74.7a10.33,10.33,0,0,1-8.25-3.937q-3.3-3.937-3.3-11.587t3.3-11.587a10.33,10.33,0,0,1,8.25-3.937,7.516,7.516,0,0,1,5.1,1.688A8.268,8.268,0,0,1,38.775,49.5h.375V44.325h8.325V64.5q0,3.75,3.3,3.75A4.68,4.68,0,0,0,54.9,65.512Q56.475,62.775,56.475,57V54.9a22.01,22.01,0,0,0-1.612-8.625,18.065,18.065,0,0,0-4.5-6.45,20.14,20.14,0,0,0-6.9-4.05A26.169,26.169,0,0,0,34.65,34.35a25.168,25.168,0,0,0-9.563,1.725A20.766,20.766,0,0,0,13.163,48.338,26.672,26.672,0,0,0,11.55,57.75V60.6a26.058,26.058,0,0,0,1.613,9.338,20.039,20.039,0,0,0,4.65,7.238,21.114,21.114,0,0,0,7.275,4.65,26.183,26.183,0,0,0,9.563,1.65H50.4Zm-16.275-22.2a4.927,4.927,0,0,0,3.487-1.463,4.351,4.351,0,0,0,1.538-3.188v-8.7a3.5,3.5,0,0,0-1.538-2.888,6.053,6.053,0,0,0-7.8.562A6.221,6.221,0,0,0,28.2,56.625v5.1a6.221,6.221,0,0,0,1.613,4.425A5.621,5.621,0,0,0,34.125,67.875Z"
      transform="translate(-4.575 -27.825)"
    />
  </svg>
)

export default EmailIcon
