import React from 'react'

const CodepenIcon = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48.816 48.009"
    fill="none"
    stroke="none"
    strokeWidth="2.05"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="codepen_icon">
      <path d="M3.06,33.777l22.986,15.25,22.986-15.25V18.318L26.046,3.068,3.06,18.318Zm45.971,0L26.046,18.318,3.06,33.777m0-15.459L26.046,33.569l22.986-15.25M26.046,3.068v15.25m0,15.459v15.25" transform="translate(-1.637 -2.043)"/>

  </svg>
);
export default CodepenIcon
