/**
 * Component to display the contact section on side of page
 * This component disappears when footer enters the viewport
 * Uses AnimatePresence to restart the animation when it appears
 */
import React, { useEffect } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import Animations from './animations/AnimationsStyles';
import Icons from './icons/Icons';
import Tooltip from '@material-ui/core/Tooltip';

// Define the props for the component
type contactProps = {
  isVisible: boolean;
};

const Contact = ({ isVisible }: contactProps) => {
  // Animation controls (FramerMotion)
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      transition: { delay: i * 0.2 },
    }));
  }, [isVisible]);

  return (
    <AnimatePresence>
      {!isVisible && (
        <motion.div exit={{ opacity: 0 }}>
          <div className="sidebar">
            <ul className="icons">
              <Tooltip title="Get in touch via LinkedIn" placement="top">
                <motion.li
                  key="linkedIn"
                  custom={0}
                  role="listitem"
                  initial={Animations.animeSettings('fadeIn').initial}
                  animate={controls}>
                  <a
                    href="https://www.linkedin.com/in/simon-bertoli-5a73893/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="View my LinkedIn page">
                    <Icons icon="linkedIn" />
                  </a>
                </motion.li>
              </Tooltip>
              <Tooltip title="View my CodePen repo" placement="top">
                <motion.li
                  key="codepen"
                  custom={1}
                  role="listitem"
                  initial={Animations.animeSettings('fadeIn').initial}
                  animate={controls}>
                  <a
                    href="https://codepen.io/simon3073"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="View my Codepen page">
                    <Icons icon="codepen" />
                  </a>
                </motion.li>
              </Tooltip>
              <Tooltip title="View my GitHub repo" placement="top">
                <motion.li
                  key="github"
                  custom={2}
                  role="listitem"
                  initial={Animations.animeSettings('fadeIn').initial}
                  animate={controls}>
                  <a
                    href="https://github.com/simon3073"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="View my GitHub page">
                    <Icons icon="github" />
                  </a>
                </motion.li>
              </Tooltip>
              <Tooltip title="Download my 1-Page CV" placement="top">
                <motion.li
                  key="resume"
                  custom={3}
                  role="listitem"
                  initial={Animations.animeSettings('fadeIn').initial}
                  animate={controls}>
                  <a href={'/resume.pdf'} target="_blank" rel="noreferrer" aria-label="download my 1 page resume">
                    <Icons icon="resume" />
                  </a>
                </motion.li>
              </Tooltip>
            </ul>
            <motion.a
              className="email"
              href="mailto:simonbertoli@yahoo.com.au"
              aria-label="Email Me"
              custom={4}
              initial={Animations.animeSettings('fadeIn').initial}
              animate={controls}>
              simonbertoli@yahoo.com.au
            </motion.a>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Contact;
