/**
 * Hook to determine the current window size and attach event to return updates when window resizes
 */

import { useEffect, useState } from 'react';

type WindowSizeObject = {
  innerWidth: number;
  innerHeight: number;
};

// return the window size to set the initial state on load
const currentWindowSize = (): WindowSizeObject => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};
const getWindowSize = () => {
  const [windowSize, setWindowSize] = useState(currentWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(currentWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return windowSize;
};

export default getWindowSize;
