import React from 'react';

const ReduxIcon = () => (
  <svg
    id="Group_65"
    data-name="Group 65"
    xmlns="http://www.w3.org/2000/svg"
    width="44.906"
    height="47.625"
    viewBox="0 0 44.906 55.625">
    <path
      id="redux"
      d="M34.76,33.262a3.586,3.586,0,0,0-.383-7.15H34.25a3.572,3.572,0,0,0-3.447,3.7,3.666,3.666,0,0,0,1.021,2.362c-2.17,4.278-5.49,7.406-10.47,10.023a17.264,17.264,0,0,1-10.406,1.979,8.874,8.874,0,0,1-6.512-3.767,9.378,9.378,0,0,1-.51-9.9,15.027,15.027,0,0,1,4.341-5.107c-.255-.83-.638-2.234-.83-3.256-9.257,6.7-8.3,15.769-5.49,20.046,2.107,3.192,6.384,5.171,11.108,5.171a15.7,15.7,0,0,0,3.83-.447A24.5,24.5,0,0,0,34.76,33.262ZM46,25.345c-4.852-5.682-12-8.81-20.173-8.81H24.8a3.508,3.508,0,0,0-3.128-1.915h-.128a3.576,3.576,0,0,0,.128,7.15H21.8A3.6,3.6,0,0,0,24.929,19.6h1.149a24.331,24.331,0,0,1,13.6,4.15,17.1,17.1,0,0,1,6.767,8.172,8.849,8.849,0,0,1-.128,7.533,9.372,9.372,0,0,1-8.746,5.235,16.328,16.328,0,0,1-6.256-1.341c-.7.638-1.979,1.66-2.873,2.3a19.709,19.709,0,0,0,8.235,1.979c6.129,0,10.661-3.384,12.385-6.767,1.851-3.7,1.724-10.087-3.064-15.513Zm-32.43,9a3.586,3.586,0,0,0,3.575,3.447h.128a3.576,3.576,0,0,0-.128-7.15h-.128a1.088,1.088,0,0,0-.447.064,23.391,23.391,0,0,1-3.32-14.173,17.126,17.126,0,0,1,3.766-9.9c1.851-2.362,5.426-3.511,7.852-3.575,6.767-.128,9.639,8.3,9.831,11.683.83.191,2.234.638,3.192.958C37.122,5.363,30.738,0,24.61,0,18.864,0,13.566,4.15,11.459,10.278,8.522,18.45,10.437,26.3,14.012,32.5a2.869,2.869,0,0,0-.447,1.851Z"
      fill="#64c0e8"
    />
  </svg>
);

export default ReduxIcon;
