import React from 'react'

const GithubIcon = () =>  (
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 51.975 50.692"
        fill="none"
        className="github_icon">
        <path d="M25.985,0a25.99,25.99,0,0,0-8.211,50.647c1.3.238,1.774-.565,1.774-1.254,0-.616-.024-2.251-.037-4.419-7.227,1.57-8.754-3.484-8.754-3.484-1.182-3-2.886-3.8-2.886-3.8-2.36-1.611.18-1.579.18-1.579a5.46,5.46,0,0,1,3.98,2.679c2.316,3.971,6.081,2.824,7.562,2.16a5.563,5.563,0,0,1,1.65-3.475c-5.77-.656-11.838-2.886-11.838-12.844a10.047,10.047,0,0,1,2.675-6.973,9.336,9.336,0,0,1,.255-6.877s2.182-.7,7.146,2.664a24.645,24.645,0,0,1,13.012,0c4.963-3.363,7.141-2.664,7.141-2.664a9.326,9.326,0,0,1,.258,6.877,10.037,10.037,0,0,1,2.672,6.973c0,9.984-6.077,12.18-11.866,12.823a6.208,6.208,0,0,1,1.763,4.813c0,3.473-.032,6.276-.032,7.128,0,.7.469,1.5,1.787,1.251A25.991,25.991,0,0,0,25.985,0" transform="translate(0 0.001)"/>

        </svg>
)
  
export default GithubIcon

