import React from 'react'

const ResumeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.927 50.491"
    fill="none"
    className="resume_icon"
  >
    <path
      d="M32.926,50.491H2a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,2,0H32.926a5.006,5.006,0,0,1,5,5V45.491A5.005,5.005,0,0,1,32.926,50.491ZM6.034,40.76a.991.991,0,0,0,0,1.983H29.058a.991.991,0,0,0,0-1.983Zm0-6.311a.989.989,0,1,0,0,1.978H29.058a.989.989,0,1,0,0-1.978Zm0-6.316a.991.991,0,0,0,0,1.983H29.058a.991.991,0,0,0,0-1.983ZM17.544,7.1a8.474,8.474,0,1,0,6.707,13.638l0,0,0,0a8.377,8.377,0,0,0,1.763-5.156A8.485,8.485,0,0,0,17.544,7.1Zm0,14.97a6.449,6.449,0,0,1-4.493-1.817,6.459,6.459,0,0,1,8.986,0A6.453,6.453,0,0,1,17.544,22.069Zm-5.7-3.389h0a6.495,6.495,0,1,1,11.4,0,8.4,8.4,0,0,0-2.824-1.72,3.52,3.52,0,0,0,.693-2.092,3.567,3.567,0,1,0-6.45,2.089,8.206,8.206,0,0,0-2.821,1.725Zm5.7-2.229a1.587,1.587,0,1,1,1.587-1.586A1.588,1.588,0,0,1,17.548,16.451Z"
      transform="translate(3.001)"
    />
  </svg>
)

export default ResumeIcon
