/**
 * Component to display the Hero section
 * Uses GraphQL to return Markdown data
 */
import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import Animations from './animations/AnimationsStyles';

// Return all Markdown files with the 'hero' el
const homeData = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { el: { eq: "hero" } } }) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);
  return data.allMarkdownRemark.edges[0].node;
};

// Define the props for the component
type scrolltoProps = {
  scrollto: (el: string) => void;
};

const Hero = ({ scrollto }: scrolltoProps) => {
  // call the useStaticQuery to retrieve hero
  const homeText = homeData();

  return (
    <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.1 }}>
      <motion.div className="section hero" variants={Animations.animeSettings('sideEntry', 'onScroll')}>
        <div className="panel-left hero-headers">
          <div className="hero-name">Simon Bertoli</div>
          <div className="hero-role">DEVELOPER</div>
        </div>
        <div className="panel-right hero-text">
          <div dangerouslySetInnerHTML={{ __html: homeText.html }} />
          <button onClick={() => scrollto('aboutRef')}>SEE MORE</button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Hero;
